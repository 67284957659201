import React, { useContext } from "react";
import LoginModal from "./LoginModal";
import { Link } from "react-router-dom";
import { UserContext } from "../../helpers/UserContext";
import UserDropDown from "./UserDropDown";
import { ContextInterface } from "../../interfaces/UserInterface";

export default function NavBar() {
  const Context : ContextInterface = useContext(UserContext);
  return (
    <>
      <div className="navbar bg-primary text-primary-content">
        <div className="navbar-start">
          <Link to="/" className="btn btn-ghost normal-case text-2xl font-normal">
            the wassie games
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal p-0">
            {/* <li>
              <Link to="/stats">about</Link>
            </li> */}
          </ul>
        </div>
        <div className="navbar-end">
          {Context.UserInterface ? (
            <UserDropDown />
          ) : (
            <label
              htmlFor="LoginModal"
              className="btn glass btn-secondary px-8 text-xl font-normal"
            >
              login
            </label>
          )}
        </div>
      </div>
      <LoginModal />
    </>
  );
}
