import * as React from "react";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
import { ConnectedAddressInfo } from "../User/ConnectedAddressInfo";
import GetAddresses from "../../api/GetAddresses";
import ConnectedAddressHeader from "./ConnectedAddressHeader";
import EnrolButton from "./EnrolButton";
import FetchNonce from "../../api/GetNonce";
import {
  AddressHoldings,
  AddressInfo,
} from "../../interfaces/AddressInterfaces";
import Loading from "../Loading";

export const EnrolWidget = ({
  Refetch,
  AddressInfoArray,
}: {
  Refetch: () => void;
  AddressInfoArray: AddressInfo[];
}) => {
  const { address, connector, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();

  const [nonce, setNonce] = React.useState("");
  const [addressState, setAddressState] = React.useState<{
    coldAddresses?: string;
    bIsDelegated?: boolean;
    addressHoldings?: AddressHoldings[];
    bAlreadyEnlisted?: boolean;
    bHasWassies?: boolean;
    error?: Error;
    loading?: boolean;
  }>({
    loading: true,
  });

  // Fetch user when:
  React.useEffect(() => {
    const handler = async () => {
      if (address) {
        GetAddresses(address)
          .then(function (response) {
            if (response) {
              let bCheckingEnlisted = false;
              for (const address of AddressInfoArray) {
                if (address.hotAddress === response.data.hotAddress) {
                  bCheckingEnlisted = true;
                  break;
                }
              }
              setAddressState((x) => ({
                ...x,
                coldAddresses: response.data.coldAddresses,
                bIsDelegated: response.data.bIsDelegated,
                addressHoldings: response.data.addressHoldings,
                bAlreadyEnlisted: bCheckingEnlisted,
                loading: false,
              }));
            }
          })
          .catch(function (error) {
            setAddressState((x) => ({ ...x, error: error }));
          });
      }
    };

    // 1. page loads
    handler();
    // 2. window is focused (in case user logs out of another window)
    window.addEventListener("focus", handler);
    return () => window.removeEventListener("focus", handler);
  }, [address, AddressInfoArray]);

  React.useEffect(() => {
    if (addressState.addressHoldings) {
      let bHasWassies = false;
      for (const address of addressState.addressHoldings) {
        if (address.OwnedWassies.length > 0) {
          bHasWassies = true;
          break;
        }
      }
      setAddressState((x) => ({ ...x, bHasWassies: bHasWassies }));
    }
  }, [addressState.addressHoldings]);

  React.useEffect(() => {
    FetchNonce()
      .then((nonce) => {
        setNonce(nonce);
      })
      .catch((error) => {
        setNonce("");
      });
  }, []);

  if (isConnected) {
    return (
      <div>
        <ConnectedAddressHeader
          ensName={ensName}
          hotAddress={address}
          bIsDelegated={addressState.bIsDelegated}
        />

        <div className="card bg-neutral shadow-xl">
          <div className="card-body p-5 pt-3">
            {addressState.bIsDelegated &&
            <p className="text-l pt-1 font-TatersRegular">
              successfully connected with {connector?.name.toLowerCase()}!
            </p>}
            {addressState.loading && (
              <div className="flex flex-col">
                <Loading />
                <button
                  className="btn btn-primary grow"
                  onClick={() => disconnect()}
                >
                  disconnect
                </button>
              </div>
            )}
            {addressState.bIsDelegated &&
              !addressState.loading && (
                <div className="space-y-2">
                  <div>
                    <ConnectedAddressInfo
                      bHasWassies={addressState.bHasWassies}
                      bAlreadyEnrolled={addressState.bAlreadyEnlisted}
                      addressHoldings={addressState.addressHoldings}
                    />
                  </div>

                  <div className="card-actions pt-2 grid-flow-col m-0">
                    {addressState.addressHoldings.length > 0 && (
                      <EnrolButton
                        nonce={nonce}
                        bAlreadyEnrolled={addressState.bAlreadyEnlisted}
                        FetchNonce={FetchNonce}
                        onSuccess={({ address }) => {
                          setAddressState((x) => ({
                            ...x,
                            hotAddress: address,
                          }));
                          Refetch();
                        }}
                        onError={({ error }) =>
                          setAddressState((x) => ({ ...x, error }))
                        }
                      />
                    )}

                    <button
                      className="btn btn-primary grow"
                      onClick={() => disconnect()}
                    >
                      disconnect
                    </button>
                  </div>
                </div>
              )}
            {!addressState.bIsDelegated && !addressState.loading && (
              <>
                <div>
                  <p className="text-l pt-1 font-TatersRegular pb-4">
                    stop right there!
                  </p>
                  <p className="font-normal pb-4">
                    you should only connect to this site with an eps delegated
                    address!
                  </p>
                  <p className="font-normal pb-4">
                    for more info on how to get set up with the eternal proxy
                    service{" "}
                    <a
                      className="hyperlink"
                      href="https://view.eternalproxy.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      follow the instructions here
                    </a>{" "}
                    and reconnect using your hot wallet.
                  </p>
                </div>
                <button
                  className="btn btn-primary grow"
                  onClick={() => disconnect()}
                >
                  disconnect
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="collapse">
        <input type="checkbox" className="peer" />
        <div className="btn collapse-title pr-0 pl-0 bg-primary text-xl font-TatersRegular peer-checked:bg-secondary">
          <p className="justify-center">connect with eps</p>
        </div>
        <div className="collapse-content bg-neutral text-primary-content peer-checked:bg-neutral">
          {connectors.map((connector) => (
            <div className="pt-4" key={connector.id}>
              <button
                disabled={!connector.ready}
                key={connector.id}
                className="btn btn-block btn-outline font-normal font-TatersRegular text-xl"
                onClick={() => connect({ connector })}
              >
                {connector.name}
                {!connector.ready && " (unsupported)"}
                {isLoading &&
                  connector.id === pendingConnector?.id &&
                  " (connecting)"}
              </button>
            </div>
          ))}
          {error && (
            <div className="text-center pt-4">error: {error.message}</div>
          )}
        </div>
      </div>
    </>
  );
};
