import React from 'react'

export default function SignUp() {
  // TODO create a page that shows how to get an in game account
  return (
    <div className="flex flex-col flex-grow w-screen max-w-5xl mx-auto items-center p-8">
      <div className="text-6xl font-TatersBold pb-4">sign up</div>
      <div className="flex max-w-3xl h-auto pb-4">
        {
          <img
            src={require("../assets/img/welcome.png")}
            alt="page not found"
          />
        }
      </div>
      <div className="pb-10 space-y-2 w-screen max-w-3xl text-justify text-xl">
        <p>hey fren, it looks like you haven't got an in-game account yet.</p>
        <p>
          you need to have downloaded and launched the game for the first time
          before you can login and manage your account.
        </p>
        <p>
          if you have already done that and you're still having issues, let us
          know in{" "}
          <a href="https://discord.gg/nvCNsKp3TC" className="hyperlink">
            discord
          </a>
          .
        </p>
        <p>
          otherwise, you can download{" "}
          <a
            href="https://store.steampowered.com/app/1869250/the_wassie_games/"
            className="hyperlink"
          >
            the wassie games
          </a>{" "}
          from steam below!
        </p>
      </div>
      <a className="w-9/12 max-w-lg" href={"steam://rungameid/1869250/"}>
        <button className="btn btn-lg btn-primary px-4 font-normal text-2xl w-full">
          download from steam
        </button>
      </a>
    </div>
  );
}
