import React from "react";
import { AddressInfo } from "../../interfaces/AddressInterfaces";
import { EnlistedAddress } from "./EnlistedAddress";

export const EnlistedAddresses = ({
  AddressInfoArray,
  RemoveAddress,
}: {
  AddressInfoArray: AddressInfo[];
  RemoveAddress: (EthAddress: string) => void;
}) => {
  return (
    <>
      {AddressInfoArray.map((AddressInfo: AddressInfo) => {
        return (
          <EnlistedAddress
            RemoveAddress={RemoveAddress}
            AddressInfo={AddressInfo}
            key={AddressInfo.hotAddress}
          />
        );
      })}
    </>
  );
};
