import axios, { AxiosResponse } from "axios";
import { config } from "../config/constants";

export default async function RemoveAddress(EthAddress: string) {
  axios.get(config.url.API_URL + "/api/remove-address", {
      withCredentials: true,
      params: {
        address: EthAddress,
      },
    })
    .then((res: AxiosResponse) => {
      return res;
    });
}
