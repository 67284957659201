async function FetchNonce(): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_API_URL + "/api/nonce", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((nonceRes) => {
        nonceRes.json().then((nonce) => {
          resolve(nonce.nonce);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default FetchNonce;
