import React from "react";
import { useNavigate } from "react-router-dom";
import HandleLogin from "../User/HandleLogin";

export default function LoginModal() {

  let navigate = useNavigate();
  const SteamLink = () => {
    window.location.href = 'steam://rungameid/1869250/'
  }

  return (
    <>
      <input type="checkbox" id="LoginModal" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box p-0 bg-neutral">
          <div className="flex-row alert bg-primary shadow-lg px-6">
            <h3 className="text-lg font-TatersRegular">login with steam!</h3>
            <label
              htmlFor="LoginModal"
              className="btn btn-sm btn-circle btn-ghost"
            >
              ✕
            </label>
          </div>
          <div className="p-6 space-y-2">
            <p>
              your in-game account is automagically created when you launch the <a href="https://store.steampowered.com/app/1869250/the_wassie_games/" className="hyperlink">the wassie games</a> for the first time.
            </p>
            <p>
              if you haven't got your copy yet, what are you waiting for? smash that download button now!
            </p>
            <div className="flex flex-row justify-center space-x-4">
              <div className="pt-4">
                <label
                  htmlFor="LoginModal"
                  className="btn btn-outline text-lg font-normal font-TatersRegular"
                  onClick={HandleLogin}
                >
                  log in
                </label>
              </div>
              <div className="pt-4">
                <label
                  htmlFor="LoginModal"
                  className="btn btn-outline text-lg font-normal font-TatersRegular"
                  onClick={SteamLink}
                >
                  download
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
