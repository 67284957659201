import React from "react";
import truncateEthAddress from "truncate-eth-address";
import WassiesAtAddress from "./WassiesAtAddress";
import { AddressHoldings, AddressInfo } from "../../interfaces/AddressInterfaces";

export const EnlistedAddress = ({
  RemoveAddress,
  AddressInfo,
}: {
  RemoveAddress: (EthAddress: string) => void;
  AddressInfo: AddressInfo;
}) => {

  return (
    <div>
      <div className="alert grid-cols-3 bg-primary shadow-lg justify-start">
        <div className="basis-1/2 p-0">
          <img
            src={require("../../assets/img/ethereumlogo.png")}
            className="object-scale-down h-8 w-8"
            alt="eth"
          />
          <div>{truncateEthAddress(AddressInfo.hotAddress)}</div>
        </div>
        <div className="card-actions basis-1/2 justify-end">
          <button
            className="btn btn-sm btn-circle btn-ghost"
            onClick={() => RemoveAddress(AddressInfo.hotAddress)}
          >
            ✕
          </button>
        </div>
      </div>
      <div className="carousel rounded-box">
        {AddressInfo.addressHoldings.map((AddressHoldings: AddressHoldings) => {
          return <WassiesAtAddress AddressHoldings={AddressHoldings} key={AddressHoldings.EthAddress}/>;
        })}
      </div>
      <div className="divider" />
    </div>
  );
};
