import React from "react";
import truncateEthAddress from "truncate-eth-address";
import { AddressHoldings } from "../../interfaces/AddressInterfaces";

const WassiesAtAddress = ({
  AddressHoldings,
}: {
  AddressHoldings: AddressHoldings;
}) => {
  return (
    <div className="flex flex-col grow pt-1">
      <div className="alert shadow-lg bg-neutral justify-start py-2 pl-6">
        <div className="basis-1/2 p-0">
          <img
            src={require("../../assets/img/EPS.png")}
            className="object-scale-down h-5 w-5"
            alt="eth"
          />
          <div className="pt-0.5 pl-1 opacity-60">
            {truncateEthAddress(AddressHoldings.EthAddress)}
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        {AddressHoldings.OwnedWassies.map((Wassie: any) => {
          return (
            <div
              className="carousel-item pt-1 pr-1"
              key={JSON.parse(Wassie.metadata).name}
            >
              <div className="card card-compact w-48 bg-base-100 shadow-xl">
                <figure>
                  <img
                    src={JSON.parse(Wassie.metadata).image}
                    alt={JSON.parse(Wassie.metadata).name}
                  />
                </figure>
                <div className="px-4 py-2 bg-neutral">
                  <h2 className="text-lg font-TatersRegular">
                    {JSON.parse(Wassie.metadata).name.toLowerCase()}
                  </h2>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WassiesAtAddress;
