import React from "react";
import Spinner from "react-spinner-material";

export default function Loading() {
  return (
    <div className="flex flex-grow justify-center items-center content-center">
      <div className="pb-10">
        <Spinner radius={210} color={"#3F49CCFF"} stroke={8} visible={true} />
      </div>
    </div>
  );
}
