import React, { useContext } from "react";
import HandleLogout from "../User/HandleLogout";
import { UserContext } from "../../helpers/UserContext";
import { ContextInterface } from "../../interfaces/UserInterface";
import { Link } from "react-router-dom";

export default function UserDropDown() {
  const Context: ContextInterface = useContext(UserContext);
  return (
    <>
      <div className="dropdown dropdown-end">
        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
          <div className="w-10 rounded-full">
            <img src={Context.UserInterface.ProfilePicture} alt="profile" />
          </div>
        </label>
        <ul
          tabIndex={0}
          className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-primary rounded-box w-52"
        >
          <li>
            <Link to="/enlist">conscription portal</Link>
          </li>
          <li>
            <button onClick={HandleLogout}>logout</button>
          </li>
        </ul>
      </div>
    </>
  );
}
