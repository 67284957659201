import React from "react";
import { config } from "../config/constants";

export default function Login() {
  return (
    <div className="flex flex-col flex-grow w-screen max-w-5xl mx-auto items-center p-8">
      <div className="text-6xl font-TatersBold pb-4">sign in</div>
      <div className="flex max-w-3xl h-auto pb-4">
        {
          <img
            src={require("../assets/img/Unauthorised.png")}
            alt="page not found"
          />
        }
      </div>
      <div className="pb-10 space-y-2 w-screen max-w-3xl text-left text-xl">
        <p>welcome to the wassie games hq!</p>
        <p>
          sign in to manage your in-game account and enlist your bravest wassies to compete in the games.
        </p>
        <p>
          verify your steam login below to get started!
        </p>
      </div>
      <a className="w-9/12 max-w-lg" href={config.url.API_URL + "/auth/steam"}>
        <button className="btn btn-lg btn-primary px-14 font-normal text-2xl w-full">
          sign in with steam
        </button>
      </a>
    </div>
  );
}
