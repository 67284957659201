import React from "react";
import truncateEthAddress from "truncate-eth-address";

const ConnectedAddressHeader = ({ ensName, hotAddress, bIsDelegated }) => {
  return (
    <div className="alert grid-cols-2 bg-primary shadow-lg justify-start">
      <div className="basis-1/2 p-0">
        <img
          src={require("../../assets/img/ethereumlogo.png")}
          className="object-scale-down h-8 w-8"
          alt="eth"
        />
        <div>
          {ensName
            ? `${ensName} (${truncateEthAddress(hotAddress)})`
            : truncateEthAddress(hotAddress)}
        </div>
      </div>
      {bIsDelegated ? (
        <div className="basis-1/2 p-0">
          <img
            src={require("../../assets/img/EPS.png")}
            className="object-scale-down h-8 w-8"
            alt="eps"
          />
          <div className="pl-1">connected with eps!</div>
        </div>
      ) : (
        <div className="basis-1/2 p-0">
          <img
            src={require("../../assets/img/EPS.png")}
            className="object-scale-down h-8 w-8 grayscale"
            alt="not connected with eps"
          />
          <div className="pl-1">not connected with eps!</div>
        </div>
      )}
    </div>
  );
};

export default ConnectedAddressHeader;
