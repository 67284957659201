import React from "react";

export const ConnectedAddressInfo = ({
  bHasWassies,
  bAlreadyEnrolled,
  addressHoldings,
}: {
  bHasWassies: boolean;
  bAlreadyEnrolled: boolean;
  addressHoldings: any;
}) => {
  const hasOwnedWassies =
    addressHoldings[0]?.OwnedWassies?.length > 0;

  if (hasOwnedWassies) {
    return (
      <div>
        {!bAlreadyEnrolled && (
          <>
            <p className="font-normal">
              looks like we have some new recruits... you think these wassies
              have what it takes to survive the wassie games?
            </p>
            <p className="font-normal pb-2">
              you can sign them up by verifying ownership below, if you're
              sure...
            </p>
          </>
        )}

        {bAlreadyEnrolled && (
          <>
            <p className="font-normal pb-2">
              these guys are already enrolled - you'll need to switch addresses
              to add more wassies to your in-game account.
            </p>
          </>
        )}

        <div className="carousel rounded-box pb-1">
          {addressHoldings[0].OwnedWassies.map((data: any, key: any) => {
            return (
              <div
                className="carousel-item pt-1 pr-1"
                key={JSON.parse(data.metadata).name}
              >
                <div className="card card-compact w-48 shadow-xl">
                  <figure>
                    <img
                      src={JSON.parse(data.metadata).image}
                      alt={JSON.parse(data.metadata).name}
                    />
                  </figure>
                  <div className="px-4 py-2  bg-primary">
                    <h2 className="text-lg font-TatersRegular">
                      {JSON.parse(data.metadata).name.toLowerCase()}
                    </h2>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <h1>looks like you don't have any wassies, sadge</h1>;
};
