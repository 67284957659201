import axios, { AxiosResponse } from "axios";
import { config } from "../config/constants";

async function GetEnlistedWassies(): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(config.url.API_URL + "/api/get-enlisted-wassies", {
        withCredentials: true,
      })
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default GetEnlistedWassies;
