import axios, { AxiosResponse } from "axios";
import { config } from "../../config/constants";

export default function HandleLogout() {
  return axios
    .get(config.url.API_URL + "/user/logout", {
      withCredentials: true,
    })
    .then((res: AxiosResponse) => {
      if (res.status === 200) {
        window.location.href = "/";
      }
    });
}
