import React, { useContext } from "react";
import GetEnlistedWassies from "../api/GetEnlistedWassies";
import { UserContext } from "../helpers/UserContext";
import { useQuery } from "@tanstack/react-query";
import RemoveAddress from "../api/RemoveAddress";
import { EnrolWidget } from "../components/WalletConnect/EnrolWidget";
import Loading from "../components/Loading";
import { EnlistedAddresses } from "../components/User/EnlistedAddresses";

const EnlistPage = () => {
  const context = useContext(UserContext);

  const { isLoading, isError, data, error, refetch } = useQuery<any, Error>(
    ["enlistedWassies"],
    GetEnlistedWassies,
    {
      cacheTime: 60000,
    }
  );

  const callRemoveAddress = (EthAddress: string) => {
    RemoveAddress(EthAddress).then(function (response) {
      callRefetch();
    });
  };

  const callRefetch = () => {
    console.log("refetching")
    setTimeout(() => {
      refetch();
    }, 1000);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  
  return (
    <div className="w-screen max-w-5xl mx-auto p-8">
      <p className="text-xl pb-4 font-TatersRegular">
        gm {context.UserInterface.UserName?.toLowerCase()}!
      </p>
      <div className="space-y-2 pb-4">
        <p>welcome to the wassie conscription portal!</p>
        <p>
          here you can add your wassies to your in-game account and keep track
          of which wassies you've already enlisted!
        </p>
      </div>
      <p className="text-xl pb-4 font-TatersRegular">
        verify ownership with eps
      </p>
      <div className="space-y-2">
        <p>
          you should only connect to this site using an eps delegated address!
        </p>
        <p>
          if you haven't set up delegation through the eternal proxy service
          yet,{" "}
          <a
            className="hyperlink"
            href="https://view.eternalproxy.com/"
            target="_blank"
            rel="noreferrer"
          >
            follow the instructions here
          </a>
          .
        </p>
      </div>
      <div className="divider" />
      <EnlistedAddresses
        AddressInfoArray={data}
        RemoveAddress={callRemoveAddress}
      />
      <div>
        <EnrolWidget Refetch={callRefetch} AddressInfoArray={data}/>
      </div>
    </div>
  );
};

export default EnlistPage;
