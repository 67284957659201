import axios, { AxiosResponse } from "axios";
import { config } from "../config/constants";

export default async function GetAddresses(EthAddress: string) {
  return axios
    .get(config.url.API_URL + "/api/get-addresses", {
      withCredentials: true,
      params: {
        ethAddress: EthAddress,
      },
    })
    .then((res: AxiosResponse) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}