import React from "react";
import { useAccount, useSignMessage } from "wagmi";
import { SiweMessage } from "siwe";

function EnrolButton({
  nonce,
  bAlreadyEnrolled,
  FetchNonce,
  onSuccess,
  onError,
}: {
  nonce: string;
  bAlreadyEnrolled: boolean;
  FetchNonce: () => void;
  onSuccess: (args: { address: string }) => void;
  onError: (args: { error: Error }) => void;
}) {

  const [loading, setLoading] = React.useState(false);
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const signIn = async () => {
    try {
      const chainId = 1;
      if (!address || !chainId) return;

      setLoading(true);

      // Create SIWE message with pre-fetched nonce and sign with wallet
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement:
          "these wassies have got what it takes to survive the wassie games!",
        uri: window.location.origin,
        version: "1",
        chainId: 1,
        nonce: nonce,
      });

      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });

      // Verify signature
      const verifyRes = await fetch(
        process.env.REACT_APP_API_URL + "/api/verify",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message, signature }),
        }
      );
      if (!verifyRes.ok) throw new Error("Error verifying message");

      setLoading(false);
      onSuccess({ address });
    } catch (error: any) {
      console.log("error");
      setLoading(false);
      onError({ error: error });
      FetchNonce();
    }
  };

  if (bAlreadyEnrolled)
    return (
      <button
        className="btn btn-primary basis-1/2 disabled:btn-secondary"
        disabled={true}
      >
        already enrolled
      </button>
    );

  return (
    <button
      disabled={!nonce || loading}
      onClick={signIn}
      className="btn btn-primary basis-1/2 disabled:btn-secondary"
    >
      enrol your wassies!
    </button>
  );
}

export default EnrolButton;
