import React, { useContext } from "react";
import Loading from "../components/Loading";
import { ContextInterface } from "../interfaces/UserInterface";
import Login from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import { UserContext } from "./UserContext";

export const ProtectedRoute = ({ children }) => {
  const Context: ContextInterface = useContext(UserContext);

  if (Context === null || Context.IsLoading) {
    return <Loading />;
  } else if (!Context.UserInterface && !Context.IsLoading) {
    return <Login />;
  } else if (Context.UserInterface && !Context.IsLoading) {
    if (Context.UserInterface.PlayFabId) {
      return children;
    } else {
      return <SignUp />;
    }
  }
};
