import React from 'react'

export default function PageNotFound() {
  return (
    <div className="flex flex-col flex-grow w-screen max-w-5xl mx-auto items-center p-8">
      <div className="text-6xl font-TatersBold pb-4">error 404</div>
      <div className="flex max-w-3xl h-auto pb-4">
        {<img src={require("../assets/img/404.png")} alt="page not found" />}
      </div>
      <div className="text-2xl font-TatersRegular text-center">
        oops! we couldn't find what you were looking for!
      </div>
    </div>
  );
}
