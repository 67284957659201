import React from "react";
import HandleLogin from "../components/User/HandleLogin";

export default function Home() {
  return (
    <>
      <main className="flex flex-col flex-grow w-9/12 max-w-lg mx-auto items-center justify-center pb-10">
        <div className="flex w-full h-auto py-10">
          {
            <img
              src={require("../assets/img/Logo.png")}
              alt="the wassie games"
            />
          }
        </div>
        <div className="flex-col text-xl pb-10 font-TatersRegular text-center space-y-4">
          <p>a multi-level battle royale for wassies</p>
          <p>
            demo out now!
          </p>
        </div>
        <div className="flex flex-col flex-grow btn-group space-y-4 w-full items-center">
          <a
            href="https://store.steampowered.com/app/1869250/the_wassie_games/"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <button className="btn btn-lg btn-primary px-4 font-normal text-2xl w-full">
              download the demo!
            </button>
          </a>
          <label onClick={HandleLogin} className="w-full">
            <button className="btn btn-lg btn-primary px-4 font-normal text-2xl w-full">
              enlist your wassie!
            </button>
          </label>
          <a
            href="https://discord.gg/nvCNsKp3TC"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <button className="btn btn-lg btn-primary px-4 font-normal text-2xl w-full">
              join the discord!
            </button>
          </a>
        </div>
      </main>
    </>
  );
}
