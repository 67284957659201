import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./pages/HomePage";
import Enlist from "./pages/EnlistPage";
import Stats from "./pages/StatsPage";
import PageNotFound from "./pages/PageNotFound";
import NavBar from "./components/Navigation/NavBar";
import { ProtectedRoute } from "./helpers/ProtectedRoute";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Loading from "./components/Loading";

function App() {

  return (
    <div className="flex flex-col h-screen">
      <BrowserRouter>
        <div className="flex-none">
          <NavBar />
        </div>
        <div className="flex flex-col flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/enlist"
              element={
                <ProtectedRoute>
                  <Enlist />
                </ProtectedRoute>
              }
            />
            <Route path="/stats" element={<Stats />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/spinner" element={<Loading />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
