import Axios from "axios";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { config } from "../config/constants";
import { ContextInterface } from "../interfaces/UserInterface";

export const UserContext = createContext({} as ContextInterface);

export default function Context(props: PropsWithChildren<any>) {
  const [context, setContext] = useState<ContextInterface>({
    UserInterface: null,
    IsLoading: true,
  });

  useEffect(() => {
    setContext({ UserInterface: null, IsLoading: true });
      Axios.get(config.url.API_URL + "/user/info", {
        withCredentials: true,
      }).then(res => {
        setContext({ UserInterface: res.data, IsLoading: false });
      }).catch(err => {
        setContext({ UserInterface: null, IsLoading: false });
      });
  }, []);

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}
